export const sportmap = {
  "kettle-bell-sport": "Kettlebell Sport",
  bodybuilding: "Bodybuilding",
  cf: "CrossFit",
  "olympic-weightlifting": "Olympic Weightlifting",
  powerlifting: "Powerlifting",
  strongman: "Strongman",
};

export const sportmapReverse = {
  "Kettlebell Sport": "kettle-bell-sport",
  Bodybuilding: "bodybuilding",
  CrossFit: "cf",
  "Olympic Weightlifting": "olympic-weightlifting",
  Powerlifting: "powerlifting",
  Strongman: "strongman",
};
