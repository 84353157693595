import React, { useCallback } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { sportmap } from "../utilities/sportmap";
import { useHistory } from "react-router-dom";
const gridStyle = {
  padding: "8px",
};

const ProfileForm = (props) => {
  const {
    active,
    meta,
    bio,
    handleSelection,
    listHistory,
    name,
    setBio,
    setName,
    setShowPasswordModal,
    setSocial,
    social,
    sport,
  } = props;
  const history = useHistory();
  const addCompHistory = useCallback(
    ({ idx, value }) => {
      const compHistory = [...bio["Competition history"]];
      compHistory[idx] = value;
      setBio({ ...bio, "Competition history": compHistory });
    },
    [bio, setBio]
  );

  const addCompRecord = useCallback(() => {
    const compHistory = bio["Competition history"]
      ? [...bio["Competition history"]]
      : [];
    compHistory.push("");
    setBio({ ...bio, "Competition history": compHistory });
  }, [bio, setBio]);

  const confirmProfile = useCallback(() => {
    // confirm profile will open a modal where you enter the password, then hit submit
    setShowPasswordModal(true);
  }, [setShowPasswordModal]);

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="h5" style={gridStyle}>
          Athlete Profile
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => history.push("/athlete-portal")}>Back</Button>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          required
          label="Name"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Sport"
          value={sport ?? ""}
          name="sport"
          required
          onChange={handleSelection}
          fullWidth
        >
          {Object.keys(sportmap).map((key) => (
            <MenuItem key={key} value={sportmap[key]}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Competing in 2024"
          value={active ?? ""}
          required
          name="active"
          onChange={handleSelection}
          fullWidth
        >
          {["yes", "no"].map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Has Meta Image - Admin use only"
          value={meta ?? ""}
          required
          name="meta"
          onChange={handleSelection}
          fullWidth
        >
          {["yes", "no"].map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={gridStyle}>
          Bio (all weight questions are optional)
        </Typography>
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Age"
          value={bio["Age"] ?? ""}
          onChange={(e) => setBio({ ...bio, Age: e.target.value })}
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Height"
          value={bio["Height"] ?? ""}
          onChange={(e) => setBio({ ...bio, Height: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Weight"
          value={bio["Weight"] ?? ""}
          onChange={(e) => setBio({ ...bio, Weight: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Competition Weight"
          value={bio["Competition Weight"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Competition Weight": e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        <TextField
          label="Hometown/city"
          value={bio["Hometown/city"] ?? ""}
          onChange={(e) => setBio({ ...bio, "Hometown/city": e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        <TextField
          label="Division(s) you will compete in"
          value={bio["Division(s) you will compete in"] ?? ""}
          onChange={(e) =>
            setBio({
              ...bio,
              "Division(s) you will compete in": e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        <TextField
          label="Current best lifts, scores, etc"
          value={bio["Current best lifts, scores, etc"] ?? ""}
          onChange={(e) =>
            setBio({
              ...bio,
              "Current best lifts, scores, etc": e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        <TextField
          label="Years as a vegan"
          value={bio["Years as a vegan"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Years as a vegan": e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Why you became a vegan"
          value={bio["Why you became a vegan"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Why you became a vegan": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">
          Write a summary of competition history
        </Typography>
        <Switch
          checked={listHistory}
          onChange={handleSelection}
          name="listHistory"
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="body1">List compitition history</Typography>
      </Grid>
      {!listHistory && (
        <Grid item xs={12} style={gridStyle}>
          <TextField
            label="History Competing"
            value={bio["History competing"] ?? ""}
            onChange={(e) =>
              setBio({ ...bio, "History competing": e.target.value })
            }
            fullWidth
            multiline
            minRows={1}
          />
        </Grid>
      )}
      {listHistory && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" style={gridStyle} align="center">
              Click "add another record" for each event you want to add details
              about
            </Typography>
          </Grid>
          {(bio["Competition history"] ?? []).map((record, idx) => (
            <Grid item xs={12} style={gridStyle} key={idx}>
              <TextField
                label="Event record: date, show, placement, etc"
                value={record ?? ""}
                onChange={(e) => addCompHistory({ idx, value: e.target.value })}
                fullWidth
              />
            </Grid>
          ))}
          <Button fullWidth onClick={addCompRecord}>
            add another record
          </Button>
        </>
      )}
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="What drives you to compete"
          value={bio["What drives you to compete"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "What drives you to compete": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="What do you see as the biggest advantages of being a vegan athlete"
          value={
            bio[
              "What do you see as the biggest advantages of being a vegan athlete"
            ] ?? ""
          }
          onChange={(e) =>
            setBio({
              ...bio,
              "What do you see as the biggest advantages of being a vegan athlete":
                e.target.value,
            })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="What types of supplements if any do you use?"
          value={bio["What types of supplements if any do you use?"] ?? ""}
          onChange={(e) =>
            setBio({
              ...bio,
              "What types of supplements if any do you use?": e.target.value,
            })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="How would you describe your diet/nutrition leading up to an event?"
          value={
            bio[
              "How would you describe your diet/nutrition leading up to an event?"
            ] ?? ""
          }
          onChange={(e) =>
            setBio({
              ...bio,
              "How would you describe your diet/nutrition leading up to an event?":
                e.target.value,
            })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Favorite foods/meals"
          value={bio["Favorite foods/meals"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Favorite foods/meals": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="How would you describe your training for a competition"
          value={
            bio["How would you describe your training for a competition"] ?? ""
          }
          onChange={(e) =>
            setBio({
              ...bio,
              "How would you describe your training for a competition":
                e.target.value,
            })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Favorite exercises"
          value={bio["Favorite exercises"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Favorite exercises": e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="How many hours a week do you spend training?"
          value={bio["How many hours a week do you spend training?"] ?? ""}
          onChange={(e) =>
            setBio({
              ...bio,
              "How many hours a week do you spend training?": e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Today I am most proud of"
          value={bio["Today I am most proud of"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Today I am most proud of": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={gridStyle}>
          Social Media Links (optional)
        </Typography>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Website Link"
          value={social["Website"] ?? ""}
          onChange={(e) => setSocial({ ...social, Website: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.plantbuilt.com"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Instagram Link"
          value={social["Instagram"] ?? ""}
          onChange={(e) => setSocial({ ...social, Instagram: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.instagram.com/coolvegan"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Facebook Link"
          value={social["Facebook"] ?? ""}
          onChange={(e) => setSocial({ ...social, Facebook: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.facebook.com/myprofile"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="YouTube Link"
          value={social["YouTube"] ?? ""}
          onChange={(e) => setSocial({ ...social, YouTube: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.youtube.com/@CoolVeganAthlete"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Twitter Link"
          value={social["Twitter"] ?? ""}
          onChange={(e) => setSocial({ ...social, Twitter: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.twitter.com/myprofile"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="LinkedIn Link"
          value={social["LinkedIn"] ?? ""}
          onChange={(e) => setSocial({ ...social, LinkedIn: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.linkedin.com/in/coolvegan/"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="TikTok Link"
          value={social["TikTok"] ?? ""}
          onChange={(e) => setSocial({ ...social, TikTok: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.tiktok.com/@coolvegan"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Podcast Link"
          value={social["Podcast"] ?? ""}
          onChange={(e) => setSocial({ ...social, Podcast: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://podcasts.apple.com/us/podcast/vegancast"
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <Button fullWidth onClick={confirmProfile}>
          Save and Submit
        </Button>
      </Grid>
    </>
  );
};

export default ProfileForm;
