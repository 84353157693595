const request = async (input, init) => {
  try {
    const response = await fetch(input, init);
    // console.log(response);
    // const readable = await response.json();
    // console.log(readable);
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    return response;
  } catch (err) {
    console.error("Fetch request failed.", err);
    throw new Error("Fetch request failed.", { cause: err });
  }
};

export const uploadFile = async (password, id, strProfile) => {
  const response = await request(
    `https://1yglts5dcc.execute-api.us-west-2.amazonaws.com/default/updateAthleteProfiles`,
    {
      method: "POST",
      body: JSON.stringify({ password, id }),
    }
  );

  if (response.ok) {
    const { url } = await response.json();

    return request(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Content-Length": `${strProfile.length}`,
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "max-age=0",
      },
      body: strProfile,
    });
  } else {
    console.error("API Upload failed.", response);
    throw new Error("API Upload failed.", { cause: response });
  }
};
