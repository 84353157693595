import React from "react";
import { Card, Typography } from "@mui/material";
import useWindowDimensions from "../windowDimensions";

const News = () => {
  const { width } = useWindowDimensions();
  return (
    <Card
      sx={{
        display: "flex",
        margin: "20px",
        padding: "20px",
        flexDirection: width < 900 ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        News and Media
      </Typography>
    </Card>
  );
};

export default News;
