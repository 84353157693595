import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import UserContent from "./UserContent";

const AthletePortal = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <UserContent />
      </div>
    </Auth0Provider>
  );
};

export default AthletePortal;
