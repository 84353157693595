import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, TextField, Typography, Snackbar } from "@mui/material";
import LoginButton from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const getProfile = async (id) => {
  const athleteProfile = await (
    await fetch(
      `https://plantbuilt.s3.us-west-2.amazonaws.com/athleteProfiles/${id}.json`
    )
  ).json();

  return athleteProfile;
};

const UserContent = (props) => {
  const { isAuthenticated, user } = useAuth0();
  const [userProfile, setUserProfile] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alertText, setAlertText] = useState("");
  const history = useHistory();

  console.log(isAuthenticated, user);

  useEffect(() => {
    if (isAuthenticated && !Object.keys(userProfile).length) {
      (async () => {
        try {
          if (!user.email) return console.log("could not access email");
          const response = await fetch(
            "https://7ogouyltt5.execute-api.us-west-2.amazonaws.com/default/findUser",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: user.email.toLowerCase(),
            }
          );
          console.log(response);
          const id = await response.json();
          console.log(id);

          if (!id) {
            setShowAlert(true);
            setAlertStatus("error");
            setAlertText(
              `There was a problem verifying your account. Please contact admin.`
            );
            return;
          }
          if (id === 101 || id === 100) {
            setIsAdmin(true);
          }
          const profile = await getProfile(id);
          if (profile) setUserProfile(profile);
          else {
            setShowAlert(true);
            setAlertStatus("error");
            setAlertText(
              "Please contact admin, there was an issue retrieving your profile."
            );
          }
        } catch (e) {
          console.error(e);
          setShowAlert(true);
          setAlertStatus("error");
          setAlertText(
            `There was a problem verifying your account. Your email, ${user.email}, may not registered to a user. Please contact admin.`
          );
          setUserProfile({});
        }
      })();
    }
  }, [isAuthenticated, user, userProfile]);

  const updateProfile = () => {
    let hash = `/athlete-portal/update-profile`;
    if (hash !== history.location.pathname) history.push(hash);
  };

  const adminUpdate = useCallback(async () => {
    const profile = await getProfile(searchId);
    // console.log(searchId, { profile });
    setUserProfile(profile);
  }, [searchId]);

  return (
    <div style={{ width: "100%", margin: "16px" }}>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity={alertStatus || "info"}
            variant="filled"
          >
            {alertText}
          </Alert>
        </Snackbar>
      )}
      {!isAuthenticated && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Welcome! Please Log In</Typography>
          <LoginButton />
        </div>
      )}
      {isAuthenticated && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Welcome {user.name}</Typography>
            <LoginButton />
          </div>
          <div>
            {Object.keys(userProfile).length > 0 && (
              <Link
                style={{
                  textDecoration: "none",
                  color: "#709425",
                  display: "flex",
                }}
                to={{
                  pathname: "/athlete-portal/update-profile",
                  state: { userProfile: userProfile },
                }}
              >
                <EditIcon />
                <Typography onClick={updateProfile} variant="body1" ml={"8px"}>
                  Update Profile
                </Typography>
              </Link>
            )}
          </div>
        </>
      )}
      {isAdmin && (
        <div
          style={{
            width: "400px",
            margin: "auto",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <Typography variant="body1">Admin Panel</Typography>
          <div style={{ display: "flex" }}>
            <TextField
              label="Enter id number to update"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              style={{ width: "300px" }}
            />
            <Button style={{ width: "100px" }} onClick={adminUpdate}>
              Search
            </Button>
          </div>
          {userProfile?.name && (
            <Typography>{`User: ${userProfile.name} found`}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default UserContent;
