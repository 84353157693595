import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

function LoginButton() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return !isAuthenticated ? (
    <Button
      // style={{ width: "250px" }}
      // variant="contained"
      onClick={loginWithRedirect}
    >
      Log In
    </Button>
  ) : (
    <Button
      // style={{ width: "250px" }}
      // variant="contained"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log Out
    </Button>
  );
}

export default LoginButton;
