import React, { useCallback } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PasswordModal = (props) => {
  const { hideModal, password, setPassword, showPasswordModal, submitProfile } =
    props;

  const handleSubmit = useCallback(() => {
    submitProfile();
    hideModal();
  }, [hideModal, submitProfile]);

  const handleCancel = useCallback(() => {
    setPassword("");
    hideModal();
  }, [hideModal, setPassword]);

  return (
    <Modal
      open={showPasswordModal}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <TextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          style={{ margin: "8px", marginBottom: "24px" }}
          helperText="enter the password to confirm your profile upload"
        ></TextField>
        <Button
          disabled={password ? false : true}
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          style={{ margin: "8px" }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCancel}
          style={{ margin: "8px" }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordModal;
