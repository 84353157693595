import React from "react";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import useWindowDimensions from "../windowDimensions";

const Results2023 = () => {
  const { width } = useWindowDimensions();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Paper
        style={{
          width: width * 0.8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px 0 20px 0",
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h5" component="div">
              VEGAN STRONG PLANTBUILT TEAM DOMINATES AT MR. AMERICA COMPETITION,
              WINNING 41 MEDALS, WITH 32 OF THEM BEING FIRST PLACE MEDALS
            </Typography>
            <Typography variant="h5" component="div">
              Atlantic City, NJ (October 10, 2023) – Plant-powered athletes
              crushed the competition at the 84th Mr. America Sports Festival in
              Atlantic City this past weekend, proving beyond a doubt that a
              vegan diet can fuel success on a world stage. Vegan Strong
              PlantBuilt athletes took home 41 medals, including 32 first place
              wins in Powerlifting, Strongman, CrossFit, and Kettlebell. “Mr.
              America provided a great opportunity for us to prove that plants
              have all the protein you need to be strong and healthy and to fuel
              wins at the highest levels!” said Giacomo Marchese, Vegan Strong
              PlantBuilt co-founder. “We more than doubled our wins from last
              year, and competitors asked how we were doing it.” <br />
              Vegan Strong PlantBuilt entered competitors in five Mr. America
              categories – Powerlifting, Strongman, CrossFit, Kettlebell and
              Bodybuilding. The Mr. America competition is considered one of the
              top all-natural sporting festivals in the world. <br />
              Five-time World Champion and 2021 World’s Best Lifter Ryan Stills
              led the team with eight first-place wins in the powerlifting 125kg
              class, including Best Overall Lifter for his combined lift of 1884
              pounds – 705-pound squat, a 429-pound bench press, and a 750-pound
              deadlift.
              <br />
              Nick Squires scored five gold medals and one silver in the 100kg
              class.
              <br />
              Katya Gorbacheva earned three first places in powerlifting in the
              75kg women’s class for open, bench only and deadlift only. She was
              also the best female lifter. <br />
              Other powerlifting team members taking home first place include:{" "}
              <br />
              Daniel Austin: (1st place Open and Masters in 75kg)
              <br /> Ndem Nkem: (1st place Open in 140kg) <br />
              Jason Toner: (1st place Open in 90kg) <br />
              Katie Lee Grosskopf: (1st place Open and Sub Masters in
              Heavyweight) <br />
              Brittany Billings: (1st place Open and Sub Masters in 82.5kg){" "}
              <br />
              Michelle Carlson: (1st place Masters 2 in 67.5kg)
              <br />
              Dr. Sarah Kashdan (1st place in Open and Sub Masters in 56kg){" "}
              <br />
              Bradie Crandall won the men’s middleweight Strongman title. <br />
              Ric Carroll placed third in the men’s heavyweight Strongman class.{" "}
              <br />
              Angeline Berva and Sophie Brock won the heavyweight and
              lightweight Strongman titles for the women while Veronique Cormier
              took second place in the Strongman women’s lightweight event.{" "}
              <br />
              George Matthews and Kody Kowalowski took first place in men’s
              CrossFit Open Rx while Ashley Kitchens and Sally Andersen won the
              women’s Open Rx title. <br />
              National recordholder Sara Lee won the 20kg Kettlebell marathon.{" "}
              <br />
              Hege Jenssen placed third in the 5-minute snatch. On the
              bodybuilding front,
              <br />
              Forest Nash took home two bronze medals in men’s amateur physique
              while Daiane (Day) Ozorio took home a bronze in the women’s pro
              wellness category. <br />
              Nadege Corcoran, who earned her pro card at this show last year,
              took home two bronze medals in the pro bikini open and pro bikini
              masters. <br />
              Erin Fergus placed fourth in the pro women’s physique category.
            </Typography>
          </CardContent>
        </Card>
        <img
          src={"/plantbuilt-event-flyer-2023.png"}
          alt={"2023 flyer"}
          style={{ width: width * 0.33, margin: "0 0 20px 0" }}
        />
      </Paper>
    </div>
  );
};

export default Results2023;
