import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import useWindowDimensions from "../windowDimensions";

const About = () => {
  const { width } = useWindowDimensions();
  return (
    <Card
      sx={{
        display: "flex",
        margin: "20px",
        padding: "20px",
        flexDirection: width < 900 ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: width > 900 ? "33%" : "300px",
          height: width > 900 ? "33%" : "300px",
        }}
        image={"about-us.jpeg"}
        alt={"dani and giacomo"}
      />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          PlantBuilt: A Journey of Compassionate Strength, From Roots to Records
        </Typography>
        <Typography variant="body1" gutterBottom>
          In 2012, Giacomo Marchese and Dani Taylor envisioned a team of vegan
          bodybuilders not just competing alongside non-vegan athletes, but
          triumphing over them. This idea wasn't only about showcasing the
          capabilities of vegan athletes; it was about demonstrating that they
          could surpass expectations and redefine stereotypes. ‌
        </Typography>
        <Typography variant="body1" gutterBottom>
          Giacomo and Dani reached out within their community, searching for
          those who shared their vision. The response was overwhelming and the
          first Vegan Strong PlantBuilt Team was born. The team first
          participated in Austin, Texas, at the Naturally Fit Games, a
          multi-sport expo. They didn't just participate; they dominated the
          bodybuilding stage, firmly establishing their presence in the world of
          strength sports. ‌ ‌
        </Typography>

        <Typography variant="body1" gutterBottom>
          Over the years, PlantBuilt has continued to grow, diversifying its
          athlete base to include a range of strength-based sports and
          consistently spreading the message of cruelty-free strength. Now the
          Vegan Strong PlantBuilt Team, the team's influence and impact have
          only increased. ‌‌
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          2023 marks a significant milestone in PlantBuilt's journey. This year,
          while competing for the sixth time, the team is setting a record, with
          more than 40 athletes competing in six different sports -
          powerlifting, bodybuilding, CrossFit, Olympic weightlifting,
          strongman, and kettlebell sport - at the Mr. America All American
          Sports Festival & Expo. Our international roster boasts athletes from
          Australia, Norway, France, and Canada, reflecting the global impact of
          our movement. ‌ ‌
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          2024 will be a record breaking year for PlantBuilt. For the first time
          ever, they will be bringing their largest team yet to TWO sporting
          events (Mr. America and USAFitFest) This year, while competing for the
          seventh time, the team will be setting new records and defending
          titles in - Powerlifting, Bodybuilding, CrossFit, Strongman,
          Kettlebell sport. We will also have our most competitive KettleBell
          Sport team to date which is something we are proud of and have worked
          hard at for a long time. Our international roster boasts athletes from
          Norway, UK, France, and Canada, reflecting the global impact of our
          movement.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our mission transcends the competition. We aim to influence the
          mainstream fitness industry, challenging long held assumptions and
          highlighting the power and potential of a vegan lifestyle. Our
          presence at such a high-profile event is a testament to our commitment
          to this goal.
        </Typography>
        <Typography variant="body1" gutterBottom>
          As we celebrate our 12th year of activism, we invite you to join us in
          our journey. Keep checking in for updates about the team, our
          athletes, and the competition. Here's to changing hearts, minds, and
          faces of strength sports.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default About;
